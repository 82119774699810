import Module from "../models/module/Module";
import API from "../services/api";

export default class ModuleRepository {

    static all = () => API.get('/module/all').then(result => result.data.modules);

    static get = (id) => API.get('/module/details/' + id).then(result => result.data);

    static getData = () => API.get('/module/data').then(result => result.data);

    static getTypes = (forceAll = false) => API.get('/module/type/overview?all=' + (forceAll ? 1 : 0));

    static getSettings = (moduleId = Module.getId()) => API.get('/module/settings?id=' + moduleId);

    static create = (module) => API.post('/module/create', {
        parent: module.parent,
        title: module.name,
        description: module.description,
        type: module.type,
        settings: { ...module.settings }
    });

    static update = (id, language, settings, values) => API.post('/module/update', {
        id: id,
        language: language,
        title: values.name,
        description: values.description,
        settings: { ...settings },
    });

    static delete = (module) => API.delete('/module/delete?id=' + module);

    static toggle = (id) => API.post('/module/toggle', { id: id }).then(response => {
        if (API.isSuccess(response)) {
            return response.data.active;
        }
    });
}
