import React from "react";
import * as Yup from 'yup';
import StandardFormComponent from "../basics/form/StandardFormComponent";
import { withRouter } from 'react-router-dom';
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import { LoaderComponent } from "../basics/layout";
import MediaFileUploadComponent from "../media/image/MediaFileUploadComponent";
import { ModuleRepository, ClientRepository, AttendeeRepository, DynamicFieldValueRepository } from "../../repository";
import { FormattedMessage, injectIntl } from "react-intl";
import { ButtonIcon } from "../basics/button";
import ClientHelper from "../../helpers/ClientHelper";
import FormattedMessageString from "../basics/FormattedMessageString";
import Module from "../../models/module/Module";
import { Col, Modal, Row, Table } from "react-bootstrap";
import FormToastComponent from "../basics/FormToastComponent";
import ReactToPrint from "react-to-print";
import { jsPDF } from "jspdf";
import Client from "../../models/client/Client";
import { Attendee } from "../../models";
import ModuleTypeEnum from "../../enums/ModuleTypeEnum";

class ClientImportComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            model: new Client(),
            loading: false,
            website: null,
            settings: {},
            products: [],
            roles: this.props.user.roles,
            modal: false,
            errors: [],

            export: false
        };

        this.table_ref = React.createRef();

        this.customProps = { ...this.props };
        this.isManager = CheckPermissions.role(this.state.roles, UserRolesEnum.MANAGER) && !CheckPermissions.role(this.state.roles, UserRolesEnum.RESELLER);
    }

    schema = () => Yup.object({
        file: Yup.mixed().required('CMS.Client.import.file')
    });

    render() {
        if (this.state.loading) return <LoaderComponent />;

        return (
            <Row>
                <Col lg={8}>
                    <div className="custom-data-table mb-4">
                        <FormattedMessage id="CMS.Client.import.info.step_1">
                            {value => <p>{value}</p>}
                        </FormattedMessage>

                        <ButtonIcon variant="outline-primary" size="sm" icon="fa-file-excel" onClick={() => this.export()} disabled={this.state.export}>
                            <FormattedMessageString id="CMS.Product.filter.buttons.export" />
                        </ButtonIcon>
                    </div>

                    <div className="custom-data-table mb-4">
                        <FormattedMessage id="CMS.Client.import.info.step_2">
                            {value => <p>{value}</p>}
                        </FormattedMessage>
                    </div>

                    <div className="custom-data-table mb-4">
                        <FormattedMessage id="CMS.Client.import.info.step_3">
                            {value => <p>{value}</p>}
                        </FormattedMessage>

                        <StandardFormComponent
                            {...this.customProps}
                            ref={this.table_ref}
                            parent={this}
                            model={this.state.model}
                            validationSchema={this.schema()}
                            className="client"
                            formSubmit={(values, formikActions) => {
                                ClientRepository.import(values.file, Module.get())
                                    .then(response => {
                                        FormToastComponent.successTrans('Clients', 'CMS.Client.import.notification.success');
                                        this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview');
                                    })
                                    .catch(errors => this.setState({ modal: true, errors: errors ? errors : [] }, () => {
                                        if (this._mediaFileUploadComponent) {
                                            this._mediaFileUploadComponent.reset();
                                        }
                                    }));
                            }}
                            formErrors={this.state.form_errors}
                            fields={[{
                                type: "row",
                                children: [
                                    {
                                        type: "col",
                                        md: 12,
                                        fields: [],
                                        custom: MediaFileUploadComponent,
                                        customProps: { field: 'file', formErrorName: 'file', label: 'CMS.Client.import.file', type: 'import_file', optional: false, showPreview: true, fullWidth: true, onRef: ref => this._mediaFileUploadComponent = ref }
                                    }
                                ]
                            }]}
                        />
                        {this.renderErrorModal()}
                    </div>
                </Col>
            </Row>
        );
    }

    export = () => this.setState({ export: true }, () => {
        let promises = [
            ClientRepository.all(true),
            AttendeeRepository.all(true),
            ModuleRepository.all(),
        ];
        for (let i = 1; i <= 3; i++) {
            promises.push(DynamicFieldValueRepository.all(i));
        }

        Promise.all(promises)
            .then(response => {
                // Indexes //
                // 0: client
                // 1: client module settings
                // 2: attendees
                // 3: modules
                // 4 / 5 / 6 dynamic values
                let clients = response[0];
                let attendees = response[1];
                let modules = response[2];

                let clientModule = modules.find(module => module.module.type === ModuleTypeEnum.CLIENT);
                let clientSettings = Module.getSettings(clientModule.module);

                let categories = [];

                let exportSettings = {
                    showGenders: false,
                    intl: this.props.intl
                }

                if (clientSettings.use_categories) {
                    clientSettings.references.filter(reference => reference.children.length <= 0).forEach(reference => {
                        categories.push({ id: reference.id, label: reference.depthLabel.join(' > ') });
                    })
                }
                if ('gender' in clientSettings.fields && clientSettings.fields.gender) {
                    exportSettings.showGenders = true;
                }

                let data = {
                    clients: {
                        records: clients.length ? clients : [new Client()],
                        settings: clientSettings
                    }
                };

                if (clientSettings?.use_attendees) {
                    let attendeeModule = modules.find(module => module.module.type === ModuleTypeEnum.ATTENDEE);
                    let attendeeSettings = Module.getSettings(attendeeModule.module);

                    if ('gender' in attendeeSettings.fields && attendeeSettings.fields.gender) {
                        exportSettings.showGenders = true;
                    }

                    let dynamicFieldValues = {};
                    for (let i = 1; i <= 3; i++) {
                        dynamicFieldValues[i] = response[i + 2];
                    }

                    data.attendees = {
                        records: attendees.length ? attendees : [new Attendee()],
                        settings: attendeeSettings,
                        dynamicFieldValues: dynamicFieldValues
                    };
                }

                ClientHelper.export(data, exportSettings);
                this.setState({ export: false });
            })
    })

    renderErrorModal = () => (
        <Modal show={this.state.modal} onHide={() => this.setState({ modal: !this.state.modal })} size="lg" animation={false} scrollable className="pages-show-modal" centered >
            <Modal.Header closeButton>
                <FormattedMessage id="CMS.Client.import.error.title">
                    {value => <Modal.Title>{value}</Modal.Title>}
                </FormattedMessage>
            </Modal.Header>
            <Modal.Body>
                <FormattedMessage id="CMS.Client.import.error.text">
                    {value => <p className="text-muted text-center">{value}</p>}
                </FormattedMessage>

                <Table size="sm" striped id="client-import-errors" ref={ref => this._componentRef = ref}>
                    <tr>
                        <th><FormattedMessageString id="CMS.Client.import.overview.sheet" /></th>
                        <th><FormattedMessageString id="CMS.Client.import.overview.row" /></th>
                        <th><FormattedMessageString id="CMS.Client.import.overview.field" /></th>
                        <th><FormattedMessageString id="CMS.Client.import.overview.message" /></th>
                    </tr>
                    {this.state.errors.map(error => (
                        <tr>
                            <td>{error.sheet}</td>
                            <td className="text-center">{error.row}</td>
                            <td>{error.field}</td>
                            <td>{error.message}</td>
                        </tr>
                    ))}
                </Table>

                <div className="buttons">
                    <ButtonIcon variant="outline-primary" icon="file-pdf" onClick={() => this.pdf()} className="mr-1">
                        <FormattedMessageString id="CMS.Client.import.buttons.pdf" />
                    </ButtonIcon>

                    <ReactToPrint
                        trigger={() => (
                            <ButtonIcon variant="outline-primary" icon="print" onClick={this.errorspdf}>
                                <FormattedMessageString id="CMS.Client.import.buttons.print" />
                            </ButtonIcon>
                        )}
                        content={() => this._componentRef}
                        pageStyle="
                            @page { size: 80mm 50mm; }
                            @media all {
                                .pagebreak { display: none; }
                            }
                            @media print {
                                th, td { font-size: 1.6em; }
                                .pagebreak { page-break-before: always; }
                            }
                        "
                    />
                </div>
            </Modal.Body>
        </Modal>
    );

    pdf = () => {
        // Default export is a4 paper, portrait, using millimeters for units
        let doc = new jsPDF('landscape', 'pt', 'a4');
        doc.setFontSize(10);

        doc.html(document.getElementById('client-import-errors'), {
            callback: (doc) => {
                doc.save('errors.pdf');
            },
            x: 20,
            y: 20
        });
    }
}

export default withRouter(injectIntl(ClientImportComponent));