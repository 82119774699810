import * as XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';

class AttendeeHelper {
    static export = (records, params) => {
        let workbook = {
            Sheets: {
                'attendees': XLSX.utils.json_to_sheet(records.map(record => this.convertToExportRecord(record, params)))
            },
            SheetNames: ['attendees']
        };

        this.additionalDynamicFields(workbook, params);
        let buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        FileSaver.saveAs(blob, 'attendees.xlsx');
    }

    static convertToExportRecord = (item, params) => {
        // Set general data
        let record = {
            id: item.id,
            client: item.client.internal_id,
            first_name: item.first_name,
            last_name: item.last_name,
            membership_enabled: item.membership.enabled
        };

        if (params.settings.fields && Object.keys(params.settings.fields).length > 0) {
            if (params.settings.fields.email) record.email = item.email;
            if (params.settings.fields.phone) record.phone = item.phone;
            if (params.settings.fields.national_insurance_number) record.national_insurance_number = item.national_insurance_number;
            if (params.settings.fields.gender) record.gender = item.gender;
            if (params.settings.fields.birth_date) record.birth_date = item.birth_date;
            if (params.settings.fields.medical_info) record.medical_info = item.medical_info;
        }

        if (params.settings.show_additional_dynamic_field_1) {
            record['additional_dynamic_field_1|' + params.settings.additional_dynamic_field_1_title] = item.additional_dynamic_field_1;
        }
        if (params.settings.show_additional_dynamic_field_2) {
            record['additional_dynamic_field_2|' + params.settings.additional_dynamic_field_2_title] = item.additional_dynamic_field_2;
        }
        if (params.settings.show_additional_dynamic_field_3) {
            record['additional_dynamic_field_3|' + params.settings.additional_dynamic_field_3_title] = item.additional_dynamic_field_3;
        }

        if (params.settings.membership_webshop_discount_code) {
            record.webshop_discount_code = item.webshop_discount_code
        }

        if (params.settings.use_membership) {
            record.membership_enabled = item.membership.enabled ? 1 : 0;
            record.memship_expires = item.membership.expires?.format('YYYY-MM-DD');
            record.memship_paid = item.membership.paid?.format('YYYY-MM-DD')
        }

        return record;
    }

    static additionalDynamicFields = (workbook, params) => {
        for (let i = 1; i <= 3; i++) {
            if (
                !params.settings['show_additional_dynamic_field_' + i] ||
                params.dynamicFieldValues[i].length <= 0
            ) {
                continue;
            }

            let data = [];
            params.dynamicFieldValues[i].forEach(dynamicValue => {
                data.push({ id: dynamicValue.id, label: dynamicValue.contents[0].value });
            });

            workbook.Sheets[params.settings['additional_dynamic_field_' + i + '_title']] = XLSX.utils.json_to_sheet(data);
            workbook.SheetNames.push(params.settings['additional_dynamic_field_' + i + '_title']);
        }

        return workbook;
    }
}

export default AttendeeHelper;